// extracted by mini-css-extract-plugin
export var container = "index-module--container--2bygE";
export var grobalMenu = "index-module--grobalMenu--3dR_9";
export var grobalMenuLink = "index-module--grobalMenuLink--2eryS";
export var globalMenuText = "index-module--globalMenuText--kq-50";
export var viewFitWrap = "index-module--viewFitWrap--1Hzfm";
export var sliderSectionPcWrap = "index-module--sliderSectionPcWrap--3tav_";
export var title = "index-module--title--1MVbG";
export var sliderSectionPc = "index-module--sliderSectionPc--2xhND";
export var sliderSectionSp = "index-module--sliderSectionSp--1a6pb";
export var sliderRow = "index-module--sliderRow--3ylgI";
export var sliderRowReverse = "index-module--sliderRowReverse--3wL9A";
export var sliderBlock = "index-module--sliderBlock--y_xX4";
export var slide1 = "index-module--slide1--2W00E";
export var slide2 = "index-module--slide2--r3REN";
export var sliderImageWrapper = "index-module--sliderImageWrapper--27e-f";
export var researchImage = "index-module--researchImage--2jaPS";
export var logoWrapper = "index-module--logoWrapper--2G0OF";
export var logoImage = "index-module--logoImage--1sfWG";
export var commentSection = "index-module--commentSection--2jVY0";
export var commentTitle = "index-module--commentTitle--2C3Dw";
export var comment = "index-module--comment--18jD1";
export var commentName = "index-module--commentName--17c0V";
export var professorSection = "index-module--professorSection--3s_Ug";
export var yanagiImg = "index-module--yanagiImg--1m1i-";
export var professorAbout = "index-module--professorAbout--1tnEz";
export var professorName = "index-module--professorName--3sc1W";
export var professorPortfolio = "index-module--professorPortfolio--3L7lk";
export var subTitle = "index-module--subTitle--3ST39";
export var professorDescription = "index-module--professorDescription--3BDq5";
export var professorReserchSection = "index-module--professorReserchSection--1EqCp";
export var professorReserchItems = "index-module--professorReserchItems--EjOH8";
export var researchItem = "index-module--researchItem--10KVt";
export var researchDescription = "index-module--researchDescription--1cW8Q";
export var researchItemImg = "index-module--researchItemImg--2qtpp";
export var researchName = "index-module--researchName--2H3LN";
export var fadeIn = "index-module--fadeIn--14mY2";
export var fadeinAnim = "index-module--fadeinAnim--S0UUs";
export var fadeOut = "index-module--fadeOut--yYvT3";
export var fadeoutAnim = "index-module--fadeoutAnim--29DHh";
export var researchPageLink = "index-module--researchPageLink--XJRPO";
export var researchPageText = "index-module--researchPageText--Keqlr";