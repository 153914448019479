import React, { useEffect, useRef } from 'react'
import Yanagi from '../images/yanagi.jpeg'
import Logo from '../images/logo.webp'
import openInNew from '../images/open_in_new.svg'
import * as styles from '../components/index.module.scss'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import GlobalWrapper from '../components/GlobalWrapper'
import SEO from '../components/SEO'

const IndexPage = ({ data }) => {
  const loopRef = useRef()

  const achievements = data.achievements.edges

  useEffect(() => {
    const images = document.getElementsByClassName(styles.sliderImageWrapper)
    const vw = window.innerWidth
    const logoLeft = (vw / 10) * 2
    const logoRight = (vw / 10) * 4.5
    const loop = () => {
      for (let i = 0; i < images.length; i++) {
        const left = images[i].getBoundingClientRect().left
        const right = images[i].getBoundingClientRect().right
        if (right > logoLeft && left < logoRight) {
          images[i].classList.add(styles.fadeOut)
        } else if (right == 0) {
          images[i].classList.remove(styles.fadeIn)
        } else {
          images[i].classList.remove(styles.fadeOut)
          images[i].classList.add(styles.fadeIn)
        }
      }
      loopRef.current = window.requestAnimationFrame(loop)
    }
    if (window.innerWidth > 768) {
      loop()
    }

    return () => {
      window.cancelAnimationFrame(loopRef.current)
    }
  }, [])

  const sliderImgs = data.researchs.edges.map(item => {
    return {
      key: item.node.frontmatter.key,
      image:
        item.node.frontmatter.researchImages[0].childImageSharp.gatsbyImageData,
      graduateYear: item.node.frontmatter.graduateYear,
    }
  })

  return (
    <>
      <SEO />
      <main className={styles.container}>
        <GlobalWrapper>
          <div
            className={`${styles.viewFitWrap} ${styles.sliderSectionPcWrap}`}
          >
            <div className={styles.sliderSectionPc}>
              <div className={styles.sliderRow}>
                <div className={styles.sliderBlock}>
                  {sliderImgs
                    .slice()
                    .sort(() => Math.random() - 0.5)
                    .map(item => (
                      <Link to={'/researchs/' + item.key} key={item.key}>
                        <div className={styles.sliderImageWrapper}>
                          <GatsbyImage
                            className={styles.researchImage}
                            image={getImage(item.image)}
                            alt={item.key + 'による研究成果'}
                          />
                        </div>
                      </Link>
                    ))}
                </div>
                <div className={styles.sliderBlock}>
                  {sliderImgs
                    .slice()
                    .sort(() => Math.random() - 0.5)
                    .map(item => (
                      <Link to={'/researchs/' + item.key} key={item.key}>
                        <div className={styles.sliderImageWrapper}>
                          <GatsbyImage
                            className={styles.researchImage}
                            image={getImage(item.image)}
                            alt={item.key + 'による研究成果'}
                          />
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
              <div className={styles.sliderRowReverse}>
                <div className={styles.sliderBlock}>
                  {sliderImgs
                    .slice()
                    .sort(() => Math.random() - 0.5)
                    .map(item => (
                      <Link to={'/researchs/' + item.key} key={item.key}>
                        <div className={styles.sliderImageWrapper}>
                          <GatsbyImage
                            className={styles.researchImage}
                            image={getImage(item.image)}
                            alt={item.key + 'による研究成果'}
                          />
                        </div>
                      </Link>
                    ))}
                </div>
                <div className={styles.sliderBlock}>
                  {sliderImgs
                    .slice()
                    .sort(() => Math.random() - 0.5)
                    .map(item => (
                      <Link to={'/researchs/' + item.key} key={item.key}>
                        <div className={styles.sliderImageWrapper}>
                          <GatsbyImage
                            className={styles.researchImage}
                            image={getImage(item.image)}
                            alt={item.key + 'による研究成果'}
                          />
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
              <div className={styles.sliderRow}>
                <div className={styles.sliderBlock}>
                  {sliderImgs
                    .slice()
                    .sort(() => Math.random() - 0.5)
                    .map(item => (
                      <Link to={'/researchs/' + item.key} key={item.key}>
                        <div className={styles.sliderImageWrapper}>
                          <GatsbyImage
                            className={styles.researchImage}
                            image={getImage(item.image)}
                            alt={item.key + 'による研究成果'}
                          />
                        </div>
                      </Link>
                    ))}
                </div>
                <div className={styles.sliderBlock}>
                  {sliderImgs
                    .slice()
                    .sort(() => Math.random() - 0.5)
                    .map(item => (
                      <Link to={'/researchs/' + item.key} key={item.key}>
                        <div className={styles.sliderImageWrapper}>
                          <GatsbyImage
                            className={styles.researchImage}
                            image={getImage(item.image)}
                            alt={item.key + 'による研究成果'}
                          />
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
              <div className={styles.logoWrapper}>
                <div>
                  <img
                    className={styles.logoImage}
                    src={Logo}
                    alt="柳研究室のロゴマーク"
                  />
                </div>
                <p>
                  これは公立はこだて未来大学における
                  柳研究室20年間の歩みをまとめたアーカイブサイトです。
                </p>
              </div>
            </div>
          </div>
          <div className={styles.sliderSectionSp}>
            <div className={styles.logoWrapper}>
              <div>
                <img
                  className={styles.logoImage}
                  src={Logo}
                  alt="柳研究室のロゴマーク"
                />
              </div>
              <p>
                これは公立はこだて未来大学における
                柳研究室20年間の歩みをまとめたアーカイブサイトです。
              </p>
            </div>
            <div className={styles.sliderRow}>
              <div className={styles.sliderBlock}>
                {sliderImgs
                  .slice()
                  .sort(() => Math.random() - 0.5)
                  .map(item => (
                    <Link to={'/researchs/' + item.key} key={item.key}>
                      <div className={styles.sliderImageWrapper}>
                        <GatsbyImage
                          className={styles.researchImage}
                          image={getImage(item.image)}
                          alt={item.key + 'による研究成果'}
                        />
                      </div>
                    </Link>
                  ))}
              </div>
              <div className={styles.sliderBlock}>
                {sliderImgs
                  .slice()
                  .sort(() => Math.random() - 0.5)
                  .map(item => (
                    <Link to={'/researchs/' + item.key} key={item.key}>
                      <div className={styles.sliderImageWrapper}>
                        <GatsbyImage
                          className={styles.researchImage}
                          image={getImage(item.image)}
                          alt={item.key + 'による研究成果'}
                        />
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
          <div className={styles.viewFitWrap}>
            <div className={styles.commentSection}>
              <h2 className={styles.commentTitle}>
                研究室アーカイブサイトに寄せて
              </h2>
              <p className={styles.comment}>
                テクノロジーの発達は未知の知覚情報を創出し高度なコミュニケーションを可能にしました．現代人がこのような知覚情報を身体感覚に置き換えて適応していく過程はますます複雑になり，身体性と知覚の乖離が発生しているように思います．柳研究室には身体性の知覚の統合や再構築のプロセスに着目したテーマが根底にあります．ここで学んだ学生たちの成果がアーカイブスとして編纂されたことは大変感慨深く心より感謝いたします．
              </p>
              <div className={styles.commentName}>柳英克</div>
            </div>
          </div>
          <div className={styles.professorSection}>
            <h1 className={styles.title}>PROFESSOR</h1>
            <img src={Yanagi} className={styles.yanagiImg} alt="yanagiImg" />
            <div className={styles.professorAbout}>
              <h2 className={styles.professorName}>柳英克</h2>
              <a
                className={styles.professorPortfolio}
                href="https://www.fun.ac.jp/~yanagi/"
                target="_blank"
                rel="noreferrer noopener"
              >
                ポートフォリオサイト
              </a>
              <div>
                <h3 className={styles.subTitle}>経歴</h3>
                <p className={styles.professorDescription}>
                  京都出身、デザイナー。1976年から1981年にかけて造形作家としてNHK教育テレビ番組などで造形デザインを手掛ける。1982年から1991年にはオフィス・ヤナギとしてTV・舞台・CM・絵本などのデザインを手掛ける。1991年から2000年にかけてアートスペースJAKU-REN
                  和太鼓奏者としての活動を経てマルチメディア作品による創作活動を行う。その後、2000年に公立はこだて未来大学システム情報科学部
                  助教授、2005年には教授に就任、2021年3月に退職。同年4月に京都大学大学院特任教授としての活動・ヤナギデザインとしての活動を開始。
                </p>
              </div>
            </div>
            <div className={styles.professorReserchSection}>
              <h3 className={styles.subTitle}>研究</h3>
              <div className={styles.professorReserchItems}>
                {achievements.map(item => (
                  <Link
                    to={'/achievements/' + item.node.frontmatter.key}
                    className={styles.researchItem}
                    key={item.node.frontmatter.key}
                  >
                    <GatsbyImage
                      className={styles.researchItemImg}
                      image={getImage(
                        item.node.frontmatter.researchImages[0].childImageSharp
                          .gatsbyImageData
                      )}
                      alt="柳英克による実績"
                    />
                    <div className={styles.researchDescription}>
                      <p className={styles.researchName}>
                        {item.node.frontmatter.title}
                      </p>
                    </div>
                  </Link>
                ))}
              </div>
              <Link to="/researchs" className={styles.researchPageLink}>
                <p className={styles.researchPageText}>
                  柳研究室 研究成果一覧 →
                </p>
              </Link>
            </div>
          </div>
        </GlobalWrapper>
      </main>
    </>
  )
}

export const query = graphql`
  query IndexQuery {
    researchs: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/researchs/" } }
      sort: { order: ASC, fields: frontmatter___graduateYear }
    ) {
      edges {
        node {
          frontmatter {
            key
            graduateYear
            researchImages {
              childImageSharp {
                gatsbyImageData(width: 160)
              }
            }
          }
        }
      }
    }
    achievements: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/achievements/" } }
    ) {
      edges {
        node {
          frontmatter {
            key
            title
            researchImages {
              childImageSharp {
                gatsbyImageData(width: 160)
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
